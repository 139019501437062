import React, { Component } from 'react'
import { replace } from 'react-router-redux'
import { connect } from 'react-redux'
import compose from 'lodash/fp/compose'

import env from 'env'
import prepareComponent from 'client/shared/decorators/prepare-component'
import urlFor, { QueryParams } from 'shared/tools/url-helper'
import { getPreviousUrl } from 'client/shared/helpers/redirect-helpers'

import { externalRedirect } from 'client/shared/reducers/app-reducer'

import AuthBox from 'client/shared/boxes/auth-box'
import { LayoutColumn } from 'client/shared/blocks/layout-column'
import { MinimalHeader } from 'client/shared/blocks/minimal-header'
import Spacer from 'client/shared/blocks/spacer'
import { Dispatch, State } from 'shared/types/redux'
import { CurrentUserState } from 'client/shared/types/current-user'

const prepareWrapper = prepareComponent(async function ({ store }) {
  const { currentUser, app } = store.getState()

  if (env.isServer() && currentUser.auth) {
    throw {
      response: {
        status: 302,
        location: urlFor.library(app.storedQuery),
      },
    }
  }
})

const connectWrapper = connect((state: State) => ({
  currentUser: state.currentUser,
  domain: state.app.domain,
  query: state.app.storedQuery,
}))

const wrappers = compose(prepareWrapper, connectWrapper)

type Props = {
  currentUser: CurrentUserState
  dispatch: Dispatch
  domain: string
  query?: QueryParams
}

class LoginPage extends Component<Props> {
  alreadyRedirected = false

  componentDidUpdate() {
    if (this.shouldRedirect()) {
      this.redirect()
    }
  }

  shouldRedirect() {
    return this.props.currentUser.auth
  }

  redirect() {
    const { dispatch, domain, query } = this.props
    const previousUrl = getPreviousUrl(domain)

    if (previousUrl) {
      if (/^(?:https?:)?\/\//.test(previousUrl)) {
        dispatch(externalRedirect(previousUrl, { replace: true }))
      } else {
        dispatch(replace(previousUrl))
      }
      this.alreadyRedirected = true
    } else if (!this.alreadyRedirected) {
      dispatch(replace(urlFor.library(query)))
    }
  }

  render() {
    const {
      currentUser: { auth },
    } = this.props

    return (
      <div>
        <MinimalHeader />
        <LayoutColumn>
          <Spacer size={32} />
          <LayoutColumn size={5} centered>
            {!auth && <AuthBox />}
          </LayoutColumn>
          <Spacer size="bottom-page" />
        </LayoutColumn>
      </div>
    )
  }
}

export default wrappers(LoginPage)
